<!--<mat-toolbar >

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>



        <div fxLayout="row" fxLayoutAlign="end center" fxHide fxShow.gt-xs>

            </div>

    </div>

</mat-toolbar>-->

<mat-toolbar style="background-color: rgb(67,79,79)">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <div fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <!--<a mat-button routerLink="https://www.drivecon.de/de/impressum">Impressum</a>
            <span>&bull;</span>
            <a mat-button routerLink="https://www.drivecon.de/de/datenschutz">Datenschutz</a>-->
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
            <img src="../../../../assets/images/logos/2019%20DC-Logo_CMYK%20weiß.png"  width="14%"/>
        </div>

    </div>

</mat-toolbar>

