import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {User} from '../_models';
import {environment} from '../../../environments/environment';
import {Observable} from "rxjs";
import {Data} from '../_models/data';
import {map} from "rxjs/operators";


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    private pagenumber = 1;

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/api/users/all`);
    }


    getById(id: number) {
        return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
    }

    deleteUser(id: string): Observable<User> {
        console.log(id);
        return this.http.delete<User>(`${environment.apiUrl}/api/users/${id}`);
    }

    getUser(id: string): Observable<User>{
        return this.http.get<User>(`${environment.apiUrl}/api/users/${id}`);
    }

    createUser(user: User): Observable<User> {
        return this.http.post<User>(`${environment.apiUrl}/api/auth/sign-up`, user);
    }

    updateUser(id: string, user: User): Observable<User> {
        console.log(user);
        return this.http.put<User>(`${environment.apiUrl}/api/users/${id}`, user);
    }

    updateemail(id: string, email: string) {
        return this.http.post(`${environment.apiUrl}/api/users/updateemail`, {id, email});
    }

    setnewpassword(hash: string, password: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/auth/setnewpassword`, {hash, password});
    }

    resetpass(password: string, id: string) {
        console.log(password);
        console.log(id);
        // @ts-ignore
        return this.http.post(`${environment.apiUrl}/api/auth/reset-pass`, {password, id});
    }

    getalldata() {
        return this.http.get<Data[]>(`${environment.apiUrl}/api/data`);
    }

    getcount48() {
        return this.http.get<Data[]>(`${environment.apiUrl}/api/data/count48`);
    }

    getnewData(start: string, ende: string, einheit: string) {
        return this.http.post<Data[]>(`${environment.apiUrl}/api/data/intervall`, { start, ende, einheit });

    }

    getnewtable(start: string, ende: string) {
        return this.http.post<Data[]>(`${environment.apiUrl}/api/data/alloftimestamp`, { start, ende });

    }

    getcsv(start: string, ende: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/data/csv`, { start, ende });

    }

    fetchItems(start: string, ende: string) {
        const pagenumber = this.pagenumber;
        return this.http.post<any>(`${environment.apiUrl}/api/data/lazy`, { start, ende, pagenumber});
    }

    getsort(sort_value: string, direction: string, start: string, ende: string) {
        const pagenumber = this.pagenumber;
        return this.http.post<any>(`${environment.apiUrl}/api/data/sort`, {sort_value, direction, start, ende, pagenumber});
    }

    paginatePage() {
        this.pagenumber ++;
    }

    pagenull() {
        this.pagenumber = 1;
    }
}
