<div id="angular-material" class="page-layout simple tabbed" fxLayout="column">

    <!-- HEADER -->
    <div class="header p-24 h-160" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center" style="background-color: rgb(67,79,79);">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">

            <div class="h1 mt-16" style="color: white">Erstellung / Bearbeitung eines Benutzers</div>
        </div>


    </div>
    <div>

        <div class="content-card" >
            <div class="card card-body" >
                <div style="width: 102%; float: left;">

                <form class="mat-card mat-elevation-z4 p-24 mr-24" [formGroup]="form" fxLayout="column" fxLayoutAlign="start"
                      fxFlex="1 0 auto" name="form" (submit)="edit ? updateUser() : submitUser()">

                            <div class="h2 mb-24">Bitte Angaben Überprüfen und bei bedarf ändern</div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                                    <mat-label>Name</mat-label>
                                    <label>
                                        <input matInput name="login" formControlName="login" [(ngModel)]="user.login" class="form-control" required>
                                    </label>
                                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                                    <mat-error>Name wird benötigt!</mat-error>
                                </mat-form-field>

                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                                    <mat-label>Passwort</mat-label>
                                    <label>
                                        <input matInput type="password" name="passwort" formControlName="passwort" [(ngModel)]="user.password" class="form-control" value="" required>
                                    </label>
                                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                                    <mat-error>Passwort entspricht nicht den erforderlichen Angaben!</mat-error>
                                </mat-form-field>

                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                                    <mat-label>Email</mat-label>
                                    <label>
                                        <input matInput name="email" formControlName="email" [(ngModel)]="user.email" class="form-control" required>
                                    </label>
                                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                                    <mat-error>Email wird benötigt!</mat-error>
                                </mat-form-field>

                            </div>



                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                                    <mat-label>Benutzer Rolle</mat-label>
                                    <mat-select name="role" formControlName="role" [(ngModel)]="user.role">
                                        <mat-option [value]="'Admin'">
                                            Admin
                                        </mat-option>
                                        <mat-option [value]="'User'">
                                            User
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix class="secondary-text">local_offer</mat-icon>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                                <button mat-raised-button type="submit">
                                    <span>Speichern</span>
                                </button>

                            </div>

                </form>
                </div>


            </div>

        </div>

    </div>
</div>
