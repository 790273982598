import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthenticationService, UserService} from '../_services';
import {fuseAnimations} from '../../../@fuse/animations';
import {Subject} from 'rxjs';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {first, takeUntil} from 'rxjs/operators';
import {User} from '../_models';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector     : 'reset-password',
    templateUrl  : './reset-password.component.html',
    styleUrls    : ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations

})
export class ResetPasswordComponent implements OnInit, OnDestroy
{
    resetPasswordForm: FormGroup;
    private _unsubscribeAll: Subject<any>;
    edit: boolean;
    returnUrl: string;
    error = '';
    token = '';

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _snackbar: MatSnackBar) {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.token = params['resetPasswordToken'];
            console.log(this.token);



            this.authenticationService.checktoken(this.token)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.router.navigate(['error-404']);
                });
        });

        this.resetPasswordForm = this._formBuilder.group({
            password       : ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });

    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    get f() { return this.resetPasswordForm.controls; }

    onSubmit() {
       console.log(this.token);
       const password = this.f.password.value;
       console.log(password);
       this.userService.setnewpassword(this.token, password)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['wait_password']);
                },
                err => {
                    console.log(err);
                    this.router.navigate(['error-404']);
                },
            );
    }

}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};
