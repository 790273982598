import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import {AuthenticationService} from '../_services';


@Injectable({ providedIn: 'root' })
export class AuthRoleGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    // tslint:disable-next-line:typedef
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const expectedRole = route.data.expectedRole;

        const currentUser = this.authenticationService.currentUserValue;
        const tokenPayload = jwt_decode(currentUser.token);


        /*if (tokenPayload.role !== expectedRole) {
            this.router.navigate(['/unauthorized'], { queryParams: { returnUrl: state.url } });
            return false;
        }*/
        return true;
    }
}
