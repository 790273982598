import { Component, ViewEncapsulation } from '@angular/core';
import {FuseConfigService} from '../../../../@fuse/services/config.service';


@Component({
    selector     : 'unauthorized',
    templateUrl  : './unauthorized.component.html',
    styleUrls    : ['./unauthorized.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UnauthorizedComponent
{
    /**
     * Constructor
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
    )
    {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }
}
