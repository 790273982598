import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthenticationService, UserService} from '../_services';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {fuseAnimations} from '../../../@fuse/animations';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {User} from '../_models';
import {MqttService} from '../_services/mqtt.service';
import {Mqtt} from '../_models/mqtt';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ModelComponent} from "../_templates/model/model.component";

@Component({
    selector     : 'mqtt',
    templateUrl  : './mqtt.component.html',
    styleUrls: ['./mqtt.component.scss'],
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class MqttComponent implements OnInit {
    displayedColumns1: string[] = ['username', 'password', 'topic', 'edit', 'delete'];
    dataSource1: MatTableDataSource<Mqtt>;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private mqttService: MqttService,
        private router: Router,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.mqttService.getAll().subscribe(data => {
            this.dataSource1 = new MatTableDataSource(data);
            this.dataSource1.sort = this.sort;
            this.dataSource1.paginator = this.paginator;
        });
    }


    deleteMqtt(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'model-component';
        dialogConfig.height = 'auto';
        dialogConfig.width = 'auto';
        dialogConfig.data = {
            name: 'delete_mqtt',
            title: 'Wollen Sie den Mqtt-Benutzer wirklich löschen?',
            actionButtonText: 'Nein',
            actionButtonText1: 'Ja',
            id: id
        };

        const modelDialog = this.dialog.open(ModelComponent, dialogConfig);

    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

}
