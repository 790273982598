<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center" >

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"  style="background-color: white">

            <div class="logo">
                <img src="assets/images/logos/2019 DC-Icon_CMYK.jpg">
            </div>

            <div class="title">Einloggen</div>

            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" class="form-control" ngModel [email]="true" >
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>Es wird eine gültige Email-Adresse benötigt!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Passwort</mat-label>
                    <input matInput type="password" formControlName="password" class="form-control">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>Es wird ein Passwort benötigt!</mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Angemeldet bleiben
                    </mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/forgot-password'">
                        Passwort Vergessen?
                    </a>
                </div>

                <div class="remember-forgot-password" *ngIf="error" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="center center" style="color:red">
                    <b>Email oder Passwort ist Falsch!</b>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN" [disabled]="loading">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    LOGIN
                </button>

                <div style="text-align: center;">
                    <br>
                   <!-- <a href="https://www.drivecon.de/de/datenschutz"><font size="2"> &copy; DriveCon GmbH</font></a>-->
                </div>

            </form>

        </div>

    </div>

</div>
