import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import {UnauthorizedComponent} from './unauthorized.component';


const routes = [
    {
        path     : 'unauthorized',
        component: UnauthorizedComponent
    }
];

@NgModule({
    declarations: [
        UnauthorizedComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatIconModule,
        FuseSharedModule
    ]
})
export class UnauthorizedModule
{
}
