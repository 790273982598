<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="background-color: white">

            <div class="logo">
                <img src="assets/images/logos/2019 DC-Icon_CMYK.jpg">
            </div>

            <div class="title">Passwort Zurücksetzen</div>

            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" class="form-control" required>
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error>Es wird eine gültige Email Adresse benötigt!</mat-error>
                </mat-form-field>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN" [disabled]="loading">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Passwort Zurücksetzen
                </button>
                <!--<div *ngIf="error" class="alert alert-danger mt-3 mb-0">Diese Email existiert im System nicht!</div>-->

            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Zurück zum LogIn</a>
            </div>

        </div>

    </div>

</div>
