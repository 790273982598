<div id="wait_password" fxLayout="column">

    <div id="wait_password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="wait_password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="background-color: white">

            <div class="logo">
                <img src="assets/images/logos/2019 DC-Icon_CMYK.jpg">
            </div>

            <div class="title">Ihr Passwort wurde erfolgreich zurückgesetzt!</div>


            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button color="accent" [routerLink]="'/login'" class="submit-button" aria-label="LOG IN">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Zurück zum LogIn
                </button>
            </div>

        </div>

    </div>

</div>
