<div id="angular-material" class="page-layout simple tabbed inner-scroll" fxLayout="column">

    <div class="search-results" infinite-scroll [infiniteScrollDistance]="0" [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolled)="onScroll()">

    <!-- HEADER -->
    <div class="header p-24 h-160" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center" style="background-color: rgb(67,79,79);">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
            </div>
            <div class="h1 mt-16" style="color: white">Statistiken</div>
        </div>
        <div class="mt-16 mt-sm-0">
            <button mat-button class="reference-button" target="_blank" (click)="logout()">
                <mat-icon class="mr-8" style="color: white">exit_to_app</mat-icon>
                <span style="color: white">Ausloggen</span>
            </button>
            <br>
            <br>
            <button mat-button class="reference-button" target="_blank" [routerLink]="['/profil/edit', mqttid]">
                <mat-icon class="mr-8" style="color: white">edit</mat-icon>
                <span style="color: white">Profil bearbeiten</span>
            </button>
        </div>

    </div>

    <div class="container" style="padding: 12px;">
        <form [formGroup]="dateformgroup">
        <div class="flex-container" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" style="height: 100%; width: 100%">
            <div>
                <mat-form-field style="padding: 12px; width: 25rem;">
                    <mat-label>Anzeige-Intervall wählen</mat-label>
                    <mat-select formControlName="einheit">
                        <mat-option value="day">Täglich</mat-option>
                        <mat-option value="week">Wöchentlich</mat-option>
                        <mat-option value="month">Monatlich</mat-option>
                        <mat-option value="year">Jährlich</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field style="padding: 12px;">

                        <input matInput [matDatepicker]="picker" placeholder="Start-Datum" [formControl]="start_year">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker  #picker></mat-datepicker>

                </mat-form-field>
            </div>
            <div>
                <mat-form-field style="padding: 12px;">
                    <input matInput [matDatepicker]="picker1" placeholder="End-Datum" [formControl]="end_year">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker  #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div style="padding: 12px;">
                    <button mat-raised-button color="secondary" (click)="getnewData()">Bestätigen</button>
            </div>
            <div style="padding: 12px;">

                    <button mat-raised-button color="secondary" (click)="getCSV()">CSV Export</button>

            </div>
        </div>
        </form>
    </div>

    <div>

        <div class="content p-24" style="width: 100%; margin: 0 auto">
            <div style="display: block;margin-left: auto; margin-right: auto; width: 75%; height: 75%; position: relative">

                <canvas baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [plugins]="barChartPlugins"
                        [legend]="barChartLegend"
                        [chartType]="barChartType">
                </canvas>
                <br>
                <br>
            </div>
        </div>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
                <!-- Position Column -->
                <ng-container matColumnDef="datum">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum</th>
                    <td mat-cell *matCellDef="let datas"> {{datas.start | date: 'dd.MM.yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Start</th>
                    <td mat-cell *matCellDef="let datas"> {{datas.start | date: 'H:mm'}} Uhr</td>
                </ng-container>

                <ng-container matColumnDef="ende">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ende</th>
                    <td mat-cell *matCellDef="let datas"> {{datas.ende | date: 'H:mm'}} Uhr</td>
                </ng-container>

                <ng-container matColumnDef="dauer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dauer [hh:mm:ss]</th>
                    <td mat-cell *matCellDef="let datas"> {{datas.dauer}} </td>
                </ng-container>

                <ng-container matColumnDef="opening">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Öffnungswinkel (Grad)</th>
                    <td mat-cell *matCellDef="let datas"> {{datas.opening}} </td>
                </ng-container>

                <ng-container matColumnDef="bedienort">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bedienort</th>
                    <td mat-cell *matCellDef="let datas"> {{datas.bedienort}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>


            <div *ngIf="loadedAll == false" style="height: 48px; display: flex; align-items: center; justify-content: center; background: white">
                <mat-spinner diameter="40"></mat-spinner>
            </div>
        </div>
    </div>
</div>