<div id="fuse-shortcuts" #shortcuts>

    <div class="shortcuts-mobile-toggle" *ngIf="!mobileShortcutsPanelActive" fxLayout="row" fxLayoutAlign="start center"
         fxHide fxShow.lt-md>
        <button mat-icon-button (click)="showMobileShortcutsPanel()">
            <mat-icon class="amber-600-fg">star</mat-icon>
        </button>
    </div>

</div>
