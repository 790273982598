<div id="reset-password" fxLayout="column">

    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="background-color: white">

            <div class="logo">
                <img src="assets/images/logos/2019 DC-Icon_CMYK.jpg">
            </div>

            <div class="title">Passwort zurücksetzen</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" (submit)="onSubmit()" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Passwort</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Passwort wird benötigt!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Passwort (Wiederholung)</mat-label>
                    <input matInput type="password" name="passsword"  formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        Passwort wiederholung wird benötigt!
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Passwörter müssen übereinstimmen!
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent"
                        aria-label="Passwort zurücksetzen" [disabled]="resetPasswordForm.invalid">
                    Passwort zurücksetzen
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/'">zurück zum LogIn</a>
            </div>

        </div>

    </div>

</div>
