import { Routes, RouterModule } from '@angular/router';
import {AuthRoleGuard as RoleGuard} from './main/_helpers/auth.role-guard';
import {AuthGuard} from './main/_helpers';
import {HomeComponent} from './main/home';
import {AdminComponent} from './main/admin';
import {LoginComponent} from './main/login';
import {RegisterComponent} from './main/register/register.component';
import {ProfilComponent} from './main/profil/profil.component';
import {ForgotPasswordComponent} from './main/forgot-password/forgot-password.component';
import {MqttComponent} from "./main/mqtt/mqtt.component";
import {MqttCreateComponent} from "./main/mqtt-create/mqtt-create.component";
import {Error404Component} from "./main/error/404/error-404.component";
import {UnauthorizedComponent} from "./main/error/unauthorized/unauthorized.component";
import {Wait_emailComponent} from "./main/wait_email/wait_email.component";
import {ResetPasswordComponent} from "./main/reset-password/reset-password.component";
import {Wait_passwordComponent} from "./main/wait_password/wait_password.component";


const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'wait_email',
        component: Wait_emailComponent
    },
    {
        path: 'wait_password',
        component: Wait_passwordComponent
    },
    {
        path: 'admin/edit/:id',
        component: RegisterComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'profil/edit/:id',
        component: ProfilComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [RoleGuard],
        data: { expectedRole: 'Admin' }
    },
    {
        path: 'mqtt',
        component: MqttComponent,
        canActivate: [RoleGuard],
        data: { expectedRole: 'Admin' }
    },
    {
        path: 'register',
        component: RegisterComponent,

    },
    {
        path: 'create-mqtt',
        component: MqttCreateComponent,
        canActivate: [RoleGuard],
        data: { expectedRole: 'Admin'}
    },
    {
        path: 'create-mqtt/edit/:id',
        component: MqttCreateComponent,
        canActivate: [RoleGuard],
        data: { expectedRole: 'Admin'}
    },
    {
        path: 'reset-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'error-404',
        component: Error404Component,
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent,
    },

    { path: '**', redirectTo: 'error-404' }
];

export const appRoutingModule = RouterModule.forRoot(routes);
