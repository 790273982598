<div id="angular-material" class="page-layout simple tabbed" fxLayout="column">

    <!-- HEADER -->
    <div class="header p-24 h-160" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center" style="background-color: rgb(67,79,79);">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18" style="color: white">edit</mat-icon>
                <mat-icon class="secondary-text s-16" style="color: white">chevron_right</mat-icon>
                <span class="secondary-text" style="color: white">Register</span>
            </div>
            <div class="h1 mt-16" style="color: white">Erstllung / Bearbeitung eines Benutzers</div>
        </div>


    </div>
    <div>

        <div class="content-card" >
            <div class="card card-body" >
                <div style="width: 102%; float: left;">

                    <form class="mat-card mat-elevation-z4 p-24 mr-24" [formGroup]="form" fxLayout="column" fxLayoutAlign="start"
                          fxFlex="1 0 auto" name="form" (submit)="edit ? updateMqtt() : submitMqtt()">

                        <div class="h2 mb-24">Bitte Angaben Überprüfen und bei bedarf ändern</div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                                <mat-label>Name</mat-label>
                                <label>
                                    <input matInput name="username" formControlName="username" [(ngModel)]="mqtt.username" class="form-control" required>
                                </label>
                                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                                <mat-error>Name wird benötigt!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                                <mat-label>Passwort</mat-label>
                                <label>
                                    <input matInput name="password" formControlName="password" [(ngModel)]="mqtt.password" class="form-control" value="" required>
                                </label>
                                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                                <mat-error>Passwort entspricht nicht den erforderlichen Angaben!</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                                <mat-label>Topic</mat-label>
                                <label>
                                    <input matInput name="topic" formControlName="topic" [(ngModel)]="mqtt.topic" class="form-control" required>
                                </label>
                                <mat-icon matSuffix class="secondary-text">assignment</mat-icon>
                                <mat-error>Es wird eine Topic benötigt!</mat-error>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                            <button mat-raised-button type="submit">
                                <span>Speichern</span>
                            </button>

                        </div>

                    </form>
                </div>


            </div>



        </div>

    </div>
</div>
