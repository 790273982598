import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {UserService} from '../_services';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Mqtt} from '../_models/mqtt';
import {MqttService} from '../_services/mqtt.service';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ModelComponent} from "../_templates/model/model.component";

@Component({
    selector: 'mqtt-create',
    templateUrl: './mqtt-create.component.html',
    styleUrls: ['./mqtt-create.component.scss']
})
export class MqttCreateComponent implements OnInit {

    form: FormGroup;
    private _unsubscribeAll: Subject<any>;

    mqtt: Mqtt = {
        username: '',
        password: '',
        topic: '',

    };
    edit = false;

    constructor(
        private _formBuilder: FormBuilder,
        private mqttService: MqttService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            username : ['', Validators.required],
            password  : ['', Validators.required],
            topic      : ['', Validators.required],
        });

        const params = this.activatedRoute.snapshot.params;
        if (params.id) {
            this.mqttService.getMqtt(params.id)
                .subscribe(
                    res => {
                        console.log(res);
                        this.mqtt = res;
                        this.edit = true;
                    },
                    err => console.log(err)
                );
        }
    }

    submitMqtt() {
        this.mqttService.createMqtt(this.mqtt)
            .subscribe(
                res => {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.id = 'model-component';
                    dialogConfig.height = 'auto';
                    dialogConfig.width = 'auto';
                    dialogConfig.data = {
                        name: 'create_mqtt',
                        title: 'Der Mqtt-Benutzer wurde erfolgreich erstellt!',
                        actionButtonText: 'Okay',
                    };

                    const modelDialog = this.dialog.open(ModelComponent, dialogConfig);
                    this.router.navigate(['/mqtt']);
                },
                err => console.log(err)
            );
    }

    updateMqtt() {
        // delete this.user.createdAt;
        this.mqttService.updateMqtt(this.mqtt._id, this.mqtt)
            .subscribe(
                res => {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.id = 'model-component';
                    dialogConfig.height = 'auto';
                    dialogConfig.width = 'auto';
                    dialogConfig.data = {
                        name: 'update_mqtt',
                        title: 'Die Änderungen wurden erfolgreich übernommen!',
                        actionButtonText: 'Okay',
                    };

                    const modelDialog = this.dialog.open(ModelComponent, dialogConfig);
                    this.router.navigate(['/mqtt']);
                },
                err => console.log(err)
            );
    }

}
