import {Component, Inject, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {User} from '../_models';
import {AuthenticationService, UserService} from '../_services';
import * as jwt_decode from 'jwt-decode';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ModelComponent} from '../_templates/model/model.component';

export interface DialogData {
    animal: string;
    name: string;
}


@Component({
    selector: 'app-profil-form',
    templateUrl: './profil.component.html',
    styleUrls: ['./profil.component.scss']
})

export class ProfilComponent implements OnInit {

    currentUser: User;
    decoded: any;
    mqttid: any;
    abc: any;

    user: User = {
        login: '',
        email: '',
        password: '',
        role: ''

    };

    form: FormGroup;
    edit: boolean = false;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _formBuilder: FormBuilder,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _fuseConfigService: FuseConfigService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog
    )
    {
        this._unsubscribeAll = new Subject();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            width: '50%',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.user.password = result;
        });
    }

    ngOnInit() {
        const currentUser1 = this.authenticationService.currentUserValue;
        this.decoded = jwt_decode(currentUser1.token);
        this.mqttid = this.decoded.sub;

        if (this.decoded.role === 'User') {
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true
                    },
                    toolbar: {
                        hidden: true
                    },
                    footer: {
                        hidden: false
                    },
                    sidepanel: {
                        hidden: true
                    }
                }
            };
        }

        const params = this.activatedRoute.snapshot.params;
        if (params.id) {
            this.userService.getUser(params.id)
                .subscribe(
                    res => {
                        this.user = res;
                        this.edit = true;
                    },
                    err => console.log(err)
                );
        }

        this.form = this._formBuilder.group({
            login   : [
                {
                    value   : '',
                    disabled: true
                }, Validators.required
            ],
            name  : ['', Validators.required],
            password   : ['', Validators.required],
            email  : ['bad@', Validators.email],
        });
    }



    updateUser() {
        this.userService.updateemail(this.user._id, this.user.email)
            .subscribe(
                res => {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.id = 'model-component';
                    dialogConfig.height = 'auto';
                    dialogConfig.width = 'auto';
                    dialogConfig.data = {
                        name: 'bestaetigung',
                        title: 'Daten wurden erfolgreich übernommen!',
                        actionButtonText: 'Okay'
                    };

                    const modelDialog = this.dialog.open(ModelComponent, dialogConfig);

                },
                err => {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.id = 'model-component';
                    dialogConfig.height = 'auto';
                    dialogConfig.width = 'auto';
                    dialogConfig.data = {
                        name: 'error_update_user',
                        title: 'Passwort konnten nicht geändert werden!',
                        actionButtonText: 'Okay'
                    };
                },
            );
    }
}

@Component({
    selector: 'change-password',
    templateUrl: 'change-password.html',
    styleUrls: ['./profil.component.scss']
})
export class ChangePasswordComponent implements OnInit{

    user: User = {
        login: '',
        email: '',
        password: '',
        role: ''

    };

    currentUser: User;
    decoded: any;
    mqttid: any;
    abc: any;
    resetPasswordForm: FormGroup;

    constructor(
        private userService: UserService,
        private _snackbar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ChangePasswordComponent>) {}

        ngOnInit() {
            const currentUser1 = this.authenticationService.currentUserValue;
            this.decoded = jwt_decode(currentUser1.token);
            this.mqttid = this.decoded.sub;

            this.resetPasswordForm = this._formBuilder.group({
                password       : ['', Validators.required],
                passwordConfirm: ['', [Validators.required, confirmPasswordValidator1]],
            });
        }

    get f() {
        return this.resetPasswordForm.controls; }

    onclose(): void {
            this.dialogRef.close();
        }

        onNoClick(): void {
        console.log(this.f.password.value);
        this.userService.resetpass(this.f.password.value, this.mqttid)
            .subscribe(
                res => {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.id = 'model-component';
                    dialogConfig.height = 'auto';
                    dialogConfig.width = 'auto';
                    dialogConfig.data = {
                        name: 'bestaetigung',
                        title: 'Passwort wurde erfolgreich übernommen!',
                        actionButtonText: 'Okay'
                    };

                    const modelDialog = this.dialog.open(ModelComponent, dialogConfig);

                },
                err => {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.id = 'model-component';
                    dialogConfig.height = 'auto';
                    dialogConfig.width = 'auto';
                    dialogConfig.data = {
                        name: 'error_update_user',
                        title: 'Passwort konnte nicht gespeichert werden!',
                        actionButtonText: 'Okay'
                    };

                    const modelDialog = this.dialog.open(ModelComponent, dialogConfig);
                },
            );
        this.dialogRef.close();
    }

}

export const confirmPasswordValidator1: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};

