import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UserService} from '../../_services';
import {Router} from '@angular/router';
import {MqttService} from '../../_services/mqtt.service';

@Component({
    selector: 'app-modal',
    templateUrl: './model.component.html',
    styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ModelComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: any,
        private userService: UserService,
        private mqttService: MqttService,
        private router: Router,
    ) {
        console.log(modalData);
    }

    ngOnInit() { }

    actionFunction() {
        this.modalAction(this.modalData);
        this.closeModal();
    }

    closeModal() {
        this.dialogRef.close();
    }

    modalAction(modalData: any) {
        switch (modalData.name) {
            case "delete":
                this.userService.deleteUser(modalData.id)
                    .subscribe(
                        res => {
                            location.reload();
                        },
                        err => console.log(err)
                    );
                break;

            case "delete_mqtt":
                this.mqttService.deleteMqtt(modalData.id)
                    .subscribe(
                        res => {
                            location.reload();
                        },
                        err => console.log(err)
                    );
                  break;

            default:
                break;
        }
    }

}
