import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import {User} from '../_models';
import {environment} from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;


    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/auth/login`, { email, password })
            .pipe(map(user => {
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    resetPassword(email: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/auth/request-pass`, { email });

    }

    checktoken(hash: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/auth/check-token`, { hash });

    }

    updatePassword(id: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/auth/update-pass`, { id, password });

    }

    ValidPasswordToken(body): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/auth/valid-password-token`, body);
    }

    newPassword(body): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/new-password`, body);
    }
}
