<div id="angular-material" class="page-layout simple tabbed" fxLayout="column">

    <!-- HEADER -->
    <div class="header p-24 h-160" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center" style="background-color: rgb(67,79,79);">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">

            <div class="h1 mt-16" style="color: white">Benutzer Weboberfläche</div>
        </div>
        <div class="mt-16 mt-sm-0">
        <button mat-button class="reference-button" target="_blank" (click)="logout()">
            <mat-icon class="mr-8" style="color: white">exit_to_app</mat-icon>
            <span style="color: white">Ausloggen</span>
        </button>
            <br>
            <br>
        <button mat-button class="reference-button" target="_blank" routerLink="/register">
            <mat-icon class="mr-8" style="color: white">edit</mat-icon>
            <span style="color: white">Benutzer erstellen</span>
        </button>
        </div>

    </div>
    <div>

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">
                <div class="tab-content p-24">
                            <table mat-table [dataSource]="dataSource" style="text-align: left;">

                                <ng-container matColumnDef="username">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Name </th>
                                    <td mat-cell *matCellDef="let users"> {{users.login}} </td>
                                </ng-container>

                                <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Email </th>
                                    <td mat-cell *matCellDef="let users"> {{users.email}} </td>
                                </ng-container>

                                <ng-container matColumnDef="role">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Benutzer-Rolle </th>
                                    <td mat-cell *matCellDef="let users"> {{users.role}} </td>
                                </ng-container>

                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Bearbeiten </th>
                                    <td mat-cell *matCellDef="let user">
                                        <button mat-raised-button
                                                [routerLink]="['/admin/edit', user._id]"
                                                class="add-product-button fuse-white mt-24 mt-md-0">
                                            <span>Bearbeiten</span>
                                        </button>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Löschen </th>
                                    <td mat-cell *matCellDef="let users">
                                        <button mat-raised-button
                                                (click)="deleteUser(users._id)"
                                                class="add-product-button fuse-white mt-24 mt-md-0">
                                            <span>Löschen</span>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                    <div style="height: 48px; display: flex; align-items: center; justify-content: center; background: white"></div>
                        </div>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>