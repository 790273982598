import {Component, Directive, HostListener, Inject, NgZone, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthenticationService, UserService} from '../_services';
import {MatTableDataSource} from '@angular/material/table';
import {fuseAnimations} from '../../../@fuse/animations';
import {Router} from '@angular/router';
import {Data} from '../_models/data';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import * as jwt_decode from 'jwt-decode';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {addDays, endOfYear, startOfYear} from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {Sort} from '@angular/material/sort';
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {ScrollDispatcher} from "@angular/cdk/overlay";

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD. MMMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',

    },
};

@Component({
    selector     : 'home',
    templateUrl  : './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
    ],


    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})


export class HomeComponent implements OnInit {

    options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Daten Klappbruecke Mainz CSV',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Daten Klappbruecke Mainz'
    };
    sortedData: Data[];
    data: Data[] = [];
    decoded: any;
    mqttid: any;
    anzahl48 = [];
    anzahl70 = [];
    min_date: any;
    max_date: any;
    table: any;
    dateformgroup: FormGroup;
    barChartOptions: ChartOptions = {
        responsive: true,
    };
    barChartLabels: Label[] = [];
    barChartType: ChartType = 'bar';
    barChartLegend = true;
    barChartPlugins = [];
    currentyear = new Date().getFullYear();
    start_year: any;
    end_year: any;
    select: any;
    loadedAll = false;

    sort_value = 'start';
    direction = 'asc';

    barChartData: ChartDataSets[] = [
        { data: this.anzahl48, label: 'Öffnungswinkel 48 Grad' },
        { data: this.anzahl70, label: 'Öffnungswinkel 70 Grad' }
    ];
    displayedColumns: string[] = ['datum', 'start', 'ende', 'dauer', 'opening', 'bedienort'];
    dataSource: MatTableDataSource<Data>;
    empty: any;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private scrollDispatcher: ScrollDispatcher,
        private ngZone: NgZone,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private _formBuilder: FormBuilder,
    ) {
        this.sortedData = this.data.slice();
    }


    ngOnInit() {
        console.log(window.innerHeight);
        this.start_year = new FormControl(startOfYear(new Date()));
        this.end_year = new FormControl(endOfYear(new Date()));
        this.select = new FormControl('Monatlich');
        const start_jahr = startOfYear(new Date()).toString();
        const end_jahr = endOfYear(new Date()).toString();
        const intervall = 'month';
        const currentUser = this.authenticationService.currentUserValue;
        this.decoded = jwt_decode(currentUser.token);
        this.mqttid = this.decoded.sub;
        this.max_date = addDays(new Date(this.min_date), 90);

        if (this.decoded.role !== 'Admin') {            // Config was soll angezeigt werden
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true
                    },
                    toolbar: {
                        hidden: true
                    },
                    footer: {
                        hidden: true
                    },
                    sidepanel: {
                        hidden: true
                    },

                }
            };

            }

        this.userService.getnewData(start_jahr, end_jahr, intervall).subscribe(data => {

            console.log(data);

            for (let i = 0; i < data.length; i++){                           // Befüllen des Diagramms mittls For-Schleife
                this.anzahl48[i] = data[i].count48;
                this.anzahl70[i] = data[i].count70;
                this.barChartLabels[i] = data[i].start.toString();
            }
        });

        this.fetschdata();

        this.dateformgroup = this._formBuilder.group({
            start       : ['', Validators.required],
            ende       : ['', Validators.required],
            einheit     : ['month'],
        });
    }

    sortData(sort: Sort) {
        console.log(sort);
        const data = this.data.slice();
        if (!sort.active || sort.direction === '') {        //Falls in der Tabelle kein Filter werden ausgewählt ist.
            //this.getnewData();                              //Standard Daten wieder holen.
            return;
        }

        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            const start = this.start_year.value;
            const ende = this.end_year.value;
            switch (sort.active) {
                case 'datum':
                    this.sort_value = 'datum';
                    this.direction = sort.direction;
                    break;
                case 'start':
                    this.sort_value = 'start';
                    this.direction = sort.direction;
                    break;
                case 'ende':
                    this.sort_value = 'ende';
                    this.direction = sort.direction;
                    break;
                case 'dauer':
                    this.sort_value = 'dauer';
                    this.direction = sort.direction;
                    break;
                case 'opening':
                    this.sort_value = 'opening';
                    this.direction = sort.direction;
                    break;
                case 'bedienort':
                    this.sort_value = 'bedienort';
                    this.direction = sort.direction;
                    break;
                default:
                    return 0;
            }

            this.userService.pagenull();                                                            //Seitenzahl auf NULL setzen
            this.userService.getsort(this.sort_value, this.direction, start, ende).subscribe(res => {

                console.log(res);
                this.data = res;
                this.dataSource = new MatTableDataSource(res);


            });
        });
        this.userService.paginatePage();
    }


    getnewData() {
        const start = this.start_year.value;
        const ende = this.end_year.value;
        const einheit = this.f.einheit.value;


        this.userService.pagenull();


        console.log(start);
        console.log(ende);
        console.log(einheit);
        this.userService.getnewData(start, ende, einheit)
            .subscribe(data => {


            const length = this.data.length;
            this.data.splice(0, length);

            this.dataSource = new MatTableDataSource(this.data);

            this.fetschdata();
        });

        this.userService.getnewData(start, ende, einheit)
            .subscribe(data => {
                for (let i = 0; i < this.anzahl70.length; i++) {
                    this.anzahl48.splice(i, 1);
                    this.anzahl70.splice(i, 1);
                    this.barChartLabels.splice(i);
                }

                for (let i = 0; i < data.length; i++){                           // Befüllen des Diagramms mittls For-Schleife
                    this.anzahl48[i] = data[i].count48;
                    this.anzahl70[i] = data[i].count70;
                    this.barChartLabels[i] = data[i].start.toString();
                }

            });

    }

    onScroll() {
        console.log();
        console.log('scrolled!!');
        this.fetschdata();
    }

    fetschdata() {
        const start = this.start_year.value;
        const ende = this.end_year.value;

        console.log(start);
        console.log(ende);
        console.log(this.sort_value);
        console.log(this.direction);

        this.userService.getsort(this.sort_value, this.direction, start, ende)
            .subscribe(res => {

                console.log(res);

                if (res.length === 0) {
                    this.loadedAll = true;
                }
                else {
                    this.loadedAll = false;
                }

                this.data.push(...res);

                this.dataSource = new MatTableDataSource(this.data);

                if (res.length === 0){
                    this.empty = 'Es konnten keine Daten gefunden werden!';
                }

                else {
                    this.empty = '';
                }
            });
        this.userService.paginatePage();

    }

    getCSV() {
        const csvExporter = new ExportToCsv(this.options);
        const start = this.start_year.value;
        const ende = this.end_year.value;
        const einheit = this.f.einheit.value;

        this.userService.getcsv(start, ende)
            .subscribe(res => {
                if (res.length === 0){
                    this.empty = 'Es konnten keine Daten gefunden werden!';
                }

                csvExporter.generateCsv(res);
            });


    }

    get f() { return this.dateformgroup.controls; }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
