<div id="error-404" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">404</div>

        <div class="message">Es ist leider ein Fehler aufgetreten!</div>

        <br>

        <a class="back-link" [routerLink]="'/'">Zurück zur Startseite</a>

    </div>

</div>