import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Mqtt} from "../_models/mqtt";
import {Observable} from "rxjs";
import {User} from "../_models";



@Injectable({ providedIn: 'root' })
export class MqttService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Mqtt[]>(`${environment.apiUrl}/api/mqtt`);
    }

    getById(id: number) {
        return this.http.get<Mqtt>(`${environment.apiUrl}/mqtts/${id}`);
    }

    deleteMqtt(id: string): Observable<Mqtt> {
        console.log(id);
        return this.http.delete<Mqtt>(`${environment.apiUrl}/api/mqtt/${id}`);
    }

    getMqtt(id: string): Observable<Mqtt>{
        return this.http.get<Mqtt>(`${environment.apiUrl}/api/mqtt/${id}`);
    }

    createMqtt(mqtt: Mqtt): Observable<Mqtt> {
        return this.http.post<Mqtt>(`${environment.apiUrl}/api/mqtt/create`, mqtt);
    }

    updateMqtt(id: string, mqtt: Mqtt): Observable<Mqtt> {
        return this.http.put<Mqtt>(`${environment.apiUrl}/api/mqtt/update?mqttID=${id}`, mqtt);
    }

}
