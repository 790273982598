<div id="modal-content-wrapper">
    <header id="modal-header">
        <h1 id="modal-title">{{modalData.title}}</h1>
    </header>
    <section id="modal-body">
        <p>{{modalData.description}}</p>
    </section>
    <footer id="modal-footer" style="text-align: center">
        <button mat-raised-button id="modal-action-button" (click)="closeModal()">
            {{modalData.actionButtonText}}
        </button>
        &nbsp;
        <button mat-raised-button *ngIf="modalData.actionButtonText1" id="modal-cancel-button" (click)="actionFunction()">
            {{modalData.actionButtonText1}}
        </button>
    </footer>
</div>