import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import {HomeComponent} from './main/home';
import {AdminComponent} from './main/admin';
import {ErrorInterceptor, JwtInterceptor} from './main/_helpers';
import {appRoutingModule} from './app.routing';
import {LoginModule} from './main/login/login.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {RegisterComponent} from './main/register/register.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {ChartsModule} from 'ng2-charts';
import { MatTabsModule } from '@angular/material/tabs';
import {ChangePasswordComponent, ProfilComponent} from './main/profil/profil.component';
import {ForgotPasswordModule} from './main/forgot-password/forgot-password.module';
import {ResetPasswordModule} from './main/reset-password/reset-password.module';
import {MqttComponent} from './main/mqtt/mqtt.component';
import {MqttCreateComponent} from './main/mqtt-create/mqtt-create.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {Error404Module} from './main/error/404/error-404.module';
import {UnauthorizedModule} from './main/error/unauthorized/unauthorized.module';
import {ModelComponent} from './main/_templates/model/model.component';
import {Wait_emailModule} from './main/wait_email/wait_email.module';
import {Wait_passwordModule} from './main/wait_password/wait_password.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatGridListModule, MatGridTile} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ScrollEventModule} from 'ngx-scroll-event';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AdminComponent,
        RegisterComponent,
        ProfilComponent,
        MqttComponent,
        MqttCreateComponent,
        ChangePasswordComponent,
        ModelComponent
    ],
    imports: [
        BrowserModule,
        InfiniteScrollModule,
        MatTabsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        appRoutingModule,
        LoginModule,
        ForgotPasswordModule,
        ResetPasswordModule,
        MatSnackBarModule,
        Error404Module,
        UnauthorizedModule,
        Wait_emailModule,
        Wait_passwordModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        ScrollEventModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,


        // App modules
        LayoutModule,
        SampleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatOptionModule,
        MatSelectModule,
        MatStepperModule,
        MatInputModule,
        ChartsModule,
        MatDialogModule,
        MatDatepickerModule,
        MatGridListModule,
        MatCardModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{}
