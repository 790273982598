
<div mat-dialog-content>

    <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate fxLayout="column" fxLayoutAlign="start"
          fxFlex="1 0 auto">

        <div class="h2 mb-24">Bitte ändern Sie Ihr Passwort</div>

        <mat-form-field appearance="outline">
            <mat-label>Passwort</mat-label>
            <input matInput type="password" formControlName="password" >
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error>
                Passwort wird benötigt!
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Passwort (Wiederholung)</mat-label>
            <input matInput type="password" name="passsword" [(ngModel)]="user.password" formControlName="passwordConfirm">
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                Passwort wiederholung wird benötigt!
            </mat-error>
            <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                Passwörter müssen übereinstimmen!
            </mat-error>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onclose()">Schliesen</button>
    <button mat-raised-button class="submit-button" color="accent"
            aria-label="Passwort zurücksetzen" [disabled]="resetPasswordForm.invalid" (click)="onNoClick()">
        Passwort zurücksetzen
    </button>
</div>