import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Anwendungen',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id        : 'orderDetail',
                title     : 'Kalppbrücke Mainz',
                type      : 'item',
                icon      : 'bar_chart',
                url       : '/',
                exactMatch: true
            },
        ],

    },
    {
        id       : 'applications',
        title    : 'Admin',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'dashboards',
                title    : 'Benutzerverwaltung',
                type     : 'collapsable',
                icon     : 'account_circle',
                children : [
                    {
                        id   : 'analytics',
                        title: 'Benutzer Oberfläche',
                        type : 'item',
                        url  : '/admin'
                    },
                    {
                        id   : 'analytics',
                        title: 'Benutzer Mqtt',
                        type : 'item',
                        url  : '/mqtt'
                    }
                ]
            },
        ]
    }
];
